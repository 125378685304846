import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { StaticLinks, THEMES } from 'epromo-lib/constants';
import { useAddresses } from 'epromo-lib/hooks/useAddresses';
import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';
import { getAddressId } from 'epromo-lib/hooks/useDeliveryAddress';
import {
  useUserProfile,
  userProfileSelector,
} from 'epromo-lib/store/auth/user-profile';

import { AvatarCircle } from '@components/atoms/AvatarCircle';
import { ButtonLink } from '@components/atoms/ButtonLink';
import { NavLink } from '@components/atoms/NavLink';
import { CreditBalanceBox } from '@components/molecules/CreditBalanceBox';
import { getUserMenuLinks } from '@components/molecules/UserMenu';
import { Icon } from '@components/atoms/Icon';

export const NavigationMobileProfileSettingsContent = () => {
  const { t } = useTranslation('common');
  const { data: addressesData } = useAddresses(true);
  const userProfile = useUserProfile(userProfileSelector.userProfile);

  const addressId = getAddressId();
  const isB2cAddress = isDeliveryAddressB2c(addressId);

  const userMenuLinks = getUserMenuLinks(isB2cAddress);

  const hasB2bAddress = !!addressesData?.b2b.length;

  const userName = `${userProfile?.firstName} ${userProfile?.lastName}`;

  return (
    <div className={'mx-4 mt-4 flex h-5/6 flex-col'}>
      <div className="flex flex-row pb-4 pt-2">
        <AvatarCircle name={userName} />
        <div className="flex flex-col pl-3">
          <div className="text-sm font-bold text-black">{userName}</div>
          <div className="text-xs text-gray-500">{userProfile?.email}</div>
        </div>
      </div>

      {!isB2cAddress && (
        <div className="pb-2">
          <CreditBalanceBox variant="white" />
        </div>
      )}

      <ul
        role="list"
        className="divide-y divide-neutral-100 rounded-xl   bg-white "
      >
        {userMenuLinks.map((link) => (
          <li key={link.href} className="flex items-center px-4 py-4 sm:px-6">
            <NavLink
              href={link.href}
              className="flex items-center px-2 sm:px-4"
            >
              <div className="flex-shrink-0 text-black">
                <Icon
                  name={link.iconName}
                  className={clsx(
                    link.iconName === 'cardClient' ? 'text-white' : ''
                  )}
                />
              </div>
              <div className={clsx('min-w-0 flex-1 px-2')}>
                {t(link.transKey)}
              </div>
            </NavLink>
          </li>
        ))}
      </ul>

      {!hasB2bAddress && (
        <div data-theme={THEMES.B2B}>
          <ButtonLink
            fluid
            className="mt-3"
            href={StaticLinks.SignForBusinessAccount}
          >
            {t('createB2BAcc')}
          </ButtonLink>
        </div>
      )}
    </div>
  );
};
