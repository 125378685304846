import { ReactNode, memo } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import dynamic from 'next/dynamic';

import { useAppStateTrackedStore } from 'epromo-lib/store';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { IDeviceInfo } from 'epromo-types';

import { Icon } from '@components/atoms/Icon';
import { Logo } from '@components/atoms/Logo';

const ShoppingCartLink = dynamic(
  () =>
    import('@components/molecules/ShoppingCartLink').then(
      (mod) => mod.ShoppingCartLink
    ),
  {
    loading: () => <div></div>,
  }
);

const BasketNavBar = dynamic(
  () =>
    import('@components/organisms/BasketNavBar').then(
      (mod) => mod.BasketNavBar
    ),
  {
    loading: () => <div></div>,
  }
);

const AddressSelectorButton = dynamic(
  () =>
    import('@components/organisms/AddressSelectorButton').then(
      (mod) => mod.AddressSelectorButton
    ),
  {
    ssr: false,
    loading: () => <div></div>,
  }
);

const SearchProducts = dynamic(
  () => import('../SearchProducts').then((mod) => mod.SearchProducts),
  {
    loading: () => <div></div>,
  }
);

const SlideDownMenu = dynamic(
  () =>
    import('@components/organisms/CategorySlideOver').then(
      (mod) => mod.SlideDownMenu
    ),
  {
    loading: () => <div></div>,
  }
);

type PrimaryNavigationProps = {
  flatInventoryList?: CategoryTreeItem[] | undefined;
  renderMiniCartTotals?: () => ReactNode;
  isGlobalNotificationVisible?: boolean;
  currCategory: CategoryTreeItem | undefined;
  nativeAppNotificationIsClosed: boolean;
  tokenExists: boolean;
  deviceInfo?: IDeviceInfo;
};

export const PrimaryNavigation = memo(function PrimaryNavigation({
  flatInventoryList,
  renderMiniCartTotals,
  isGlobalNotificationVisible,
  currCategory,
  nativeAppNotificationIsClosed,
  tokenExists,
}: PrimaryNavigationProps) {
  const {
    setMobileMenuOpen,
    setCategoriesMenuOpen,
    openSearch,
    setSearchInputHasFocus,
  } = useAppStateTrackedStore();

  return (
    <div className="bg-white shadow-md">
      {/* eslint-disable-next-line max-len */}
      <div className="flex h-[70px] items-center justify-start px-4 md:px-[60px]">
        <button
          type="button"
          className="inline-block pr-6 lg:hidden"
          onClick={() => {
            if (!!currCategory) {
              setCategoriesMenuOpen(true);
            } else {
              setMobileMenuOpen(true);
            }
          }}
        >
          <span className="sr-only">Open menu</span>
          <Icon name="burger" className="h-auto w-5" />
        </button>
        {/* Logo (lg+) */}
        <div className="gap-x-8 lg:flex lg:items-center">
          <Link href="/" legacyBehavior>
            <a>
              <span className="sr-only">ePROMO</span>
              <Logo />
            </a>
          </Link>
          <SlideDownMenu
            flatInventoryList={flatInventoryList}
            isGlobalNotificationVisible={isGlobalNotificationVisible}
            nativeAppNotificationIsClosed={nativeAppNotificationIsClosed}
          />
        </div>

        <div
          className={twMerge(
            'mr-0 flex flex-1',
            'items-center justify-end gap-6 md:hidden'
          )}
        >
          <button
            type="button"
            onClick={() => {
              setSearchInputHasFocus(true);
              openSearch();
            }}
          >
            <span className="sr-only">Search</span>
            <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          {tokenExists && (
            <div className="inline-flex md:hidden">
              <ShoppingCartLink />
            </div>
          )}
        </div>
        <SearchProducts />
        {tokenExists && <AddressSelectorButton />}
        {tokenExists && (
          <BasketNavBar renderMiniCartTotals={renderMiniCartTotals} />
        )}
      </div>
    </div>
  );
});
