const defaultTheme = require('tailwindcss/defaultTheme')

const primary = {
  50: '#FDF1F3',
  100: '#FADBDE',
  200: '#F5ADB6',
  300: '#EF808D',
  400: '#EA5364',
  500: '#E4263C',
  600: '#BF182B',
  700: '#8D1120',
  800: '#5F0C16',
  900: '#2D060A',
}

module.exports = {
  content: [
    '../../packages/ui/components/**/*.{ts,tsx}',
    '../../packages/ui/stories/**/*.{ts,tsx}',
  ],
  theme: {
    extend: {
      maxWidth: {
        '8xl': '1440px',
        '9xl': '1536px',
      },
      fontFamily: {
        sans: ['"Inter"', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        label: '#05071D',
        red: {
          ...primary,
        },
        primary: {
          ...primary,
        },
        secondary: {
          50: '#FFFFFF',
          100: '#FFFFFF',
          150: '#E6E8F0',
          200: '#FFFFFF',
          300: '#B9BEF3',
          400: '#222FC4',
          500: '#05071D',
          600: '#040516',
          700: '#030411',
          800: '#02030D',
          900: '#010104',
        },
        neutral: {
          50: '#FCFCFD',
          100: '#F2F3F7',
          150: '#E6E8F0',
          200: '#D7DAE5',
          300: '#B6BCCD',
          400: '#9399AE',
          500: '#5D6273',
          600: '#53596F',
          700: '#424A61',
          800: '#383F57',
          900: '#202536',
        },
        gray: {
          50: '#f4f4f7',
          100: '#e4e6e9',
          200: '#ccced5',
          300: '#a8acb8',
          400: '#7d8293',
          500: '#5d6273',
          600: '#535667',
          700: '#484a56',
          800: '#40424a',
          900: '#383841',
          DEFAULT: '#5d6273',
        },
        green: {
          50: '#E2FEF8',
          100: '#C5FCEF',
          200: '#8BF9DA',
          300: '#50F6BF',
          400: '#16F39E',
          500: '#0AC573',
          600: '#089B63',
          700: '#067450',
          800: '#044E39',
          900: '#02271E',
          DEFAULT: '#0AC573',
        },
        positive: {
          0: '#EFFFFC',
          50: '#E2FEF8',
          100: '#C5FCEF',
          200: '#8BF9DA',
          300: '#50F6BF',
          400: '#16F39E',
          500: '#0AC573',
          600: '#089B63',
          700: '#067450',
          800: '#044E39',
          900: '#02271E',
        },
        negative: {
          50: '#FDF1F3',
          100: '#FADBDE',
          200: '#F5ADB6',
          300: '#EF808D',
          400: '#EA5364',
          500: '#E4263C',
          600: '#BF182B',
          700: '#8D1120',
          800: '#5F0C16',
          900: '#2D060A',
        },
        business: {
          0: '#EFF7FF',
          50: '#E2F1FF',
          100: '#CFE6FC',
          200: '#A0CBF8',
          300: '#6BA9F5',
          400: '#3B87F2',
          500: '#1065EA',
          600: '#0D55BA',
          700: '#09448A',
          800: '#07325F',
          900: '#031A30',
        },
        warning: {
          50: '#FFF9EB',
          300: '#FFD46E',
          500: '#B27D00',
          700: '#8C6200',
        },
        discount: {
          100: '#FFFCBD',
          200: '#FEF411',
          300: '#E9D521',
        },
        'filter-button': {
          base: 'var(--color-primary-default)',
          hover: 'var(--color-primary-hover)',
          focused: 'var(--color-primary-hover)',
          bg: 'var(--color-primary-filter)',
          'bg-hover': 'var(--color-primary-filter-hover)',
        },
        purple: {
          50: '#F2ECFB',
          700: '#803BDA',
        },
        default: {
          primary: 'var(--color-primary-default)',
        },
      },
      backgroundColor: {
        skin: {
          'button-default': 'var(--color-primary-default)',
          'button-hover': 'var(--color-primary-hover)',
          'button-focus': 'var(--color-primary-focus)',
          'button-disabled': 'var(--color-primary-disabled)',
          200: 'var(--color-primary-200)',
        },
      },
      fill: {
        skin: {
          'fill-hover': 'var(--color-primary-hover)',
        },
      },
      textColor: {
        skin: {
          'text-hover': 'var(--color-primary-hover)',
          200: 'var(--color-primary-200)',
        },
      },
      borderColor: {
        skin: {
          200: 'var(--color-primary-200)',
        },
      },
      width: {
        30: '7.5rem',
      },
      gridTemplateColumns: {
        'products-sm': 'repeat(auto-fill, minmax(180px, 1fr))',
        'products-md': 'repeat(auto-fill, minmax(208px, 1fr))',
        'products-lg': 'repeat(auto-fill, minmax(244px, 1fr))',
      },
      boxShadow: {
        'checkout-card': '0px 4px 24px 0px rgba(218, 218, 218, 0.25)',
        'carousel-card': '0px 24px 44px rgba(167, 170, 193, 0.25)',
      },
    },
  },
  plugins: [
    require('@headlessui/tailwindcss'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
  ],
  future: {
    hoverOnlyWhenSupported: true,
  },
}
