const getUserAgent = (userAgent: NavigatorID['userAgent']) => {
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isMobileUserAgent = Boolean(isAndroid || isIos);
  return {
    isMobileUserAgent,
    isAndroid,
    isIos,
  };
};
export const useDetectMobileUserAgent = () => {
  const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
  return getUserAgent(userAgent);
};
