import { twMerge } from 'tailwind-merge';

import { Icon } from '@components/atoms/Icon';

export interface BackTopButtonProps {
  onClick: () => void;
  btnInView: boolean;
}

export function BackTopButton({ onClick, btnInView }: BackTopButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(
        'isolate z-30 h-12 w-12',
        'fixed text-white',
        'bottom-4 right-4',
        'md:bottom-6 md:right-6',
        'flex items-center justify-center',
        btnInView && 'hidden',
        'rounded-full bg-neutral-800',
        'hover:bg-neutral-500'
      )}
    >
      <Icon name="arrowUp" className="h-3 w-3" />
    </button>
  );
}
