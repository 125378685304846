import Link from 'next/link';
import { ReactNode } from 'react';

type NavLinkProps = {
  href: string;
  className: string;
  children: ReactNode;
};

export const NavLink = ({ href, children, className }: NavLinkProps) => (
  <Link href={href} legacyBehavior>
    <a className={className}>{children}</a>
  </Link>
);
