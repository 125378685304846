import clsx from 'clsx';
import Link from 'next/link';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { Logo } from '@components/atoms/Logo';

interface Props {
  onClose?: () => void;
  onBack?: () => void;
  title?: string;
}

export const NavigationMobileHeader = ({ onClose, onBack, title }: Props) => {
  const closeBtn = (
    <button
      type="button"
      className={clsx(
        'flex',
        'focus:outline-none focus:ring-2',
        'focus:ring-inset focus:ring-white'
      )}
      onClick={onClose}
    >
      <span className="sr-only">Close sidebar</span>
      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );

  return (
    <div
      className={clsx(
        'flex h-[70px] px-4',
        'flex-shrink-0 items-center justify-between',
        'bg-white drop-shadow-md'
      )}
    >
      {title ? (
        <div className="flex w-full justify-between">
          <div className="flex">
            <button
              type="button"
              className={clsx(
                'flex',
                'focus:outline-none focus:ring-2',
                'focus:ring-inset focus:ring-white'
              )}
              onClick={onBack}
            >
              <span className="sr-only">Back</span>
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="pl-4 font-bold">{title}</div>
          </div>
          {closeBtn}
        </div>
      ) : (
        <>
          <Link href="/" legacyBehavior>
            <a className="outline-none">
              <span className="sr-only">ePROMO</span>
              <Logo />
            </a>
          </Link>
          {closeBtn}
        </>
      )}
    </div>
  );
};
