import Link from 'next/link';
import { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';

import {
  userProfileSelector,
  useUserProfile,
} from 'epromo-lib/store/auth/user-profile';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { StaticLinks } from 'epromo-lib/constants';
import { useApp } from 'epromo-lib/hooks/useApp';
import { LanguagesEnum, ProductContract } from 'epromo-types';

import { IInnerPage } from 'epromo-lib-cms/types/payload-types';
import { getGreetingName } from 'epromo-lib/utils/language';

import { LanguageSelector } from '@components/organisms/LanguageSelector';
import { CurrentOrdersNavItem } from '@components/organisms/CurrentOrdersNavItem';
import { AvatarCircle } from '@components/atoms/AvatarCircle';

import { UserMenu } from '../UserMenu';

export type SecondaryNavItemProps = {
  href: string;
  children: ReactNode;
};

const SecondaryNavClasses = 'text-xs uppercase text-white hover:text-gray-100';

export const SecondaryNavItem = ({ href, children }: SecondaryNavItemProps) => (
  <Link href={href} legacyBehavior>
    <a
      className={twMerge(SecondaryNavClasses, 'inline-flex items-center gap-2')}
    >
      {children}
    </a>
  </Link>
);

const VerticalDivider = () => (
  <span className="h-6 w-px bg-gray-600" aria-hidden="true" />
);

type SecondaryNavigationProps = {
  product?: ProductContract;
  category?: CategoryTreeItem;
  page?: IInnerPage;
  altPage?: IInnerPage;
  tokenExists: boolean;
};

export const SecondaryNavigation = ({
  product,
  category,
  page,
  altPage,
  tokenExists,
}: SecondaryNavigationProps) => {
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const { t } = useTranslation('common');
  const { handleLanguageSwitch } = useApp();
  const { locale } = useRouter();

  const resolveUserSection = () => {
    if (tokenExists && userProfile) {
      return (
        <UserMenu
          userMail={userProfile.email}
          userName={`${userProfile.firstName} ${userProfile.lastName}`}
          popoverButtonContent={
            <div
              className={twMerge(
                SecondaryNavClasses,
                'inline-flex items-center gap-2 text-white'
              )}
            >
              <AvatarCircle name={userProfile.firstName} size="sm" />
              <div>
                <span className="pr-1">{t('hi')},</span>
                {getGreetingName(
                  locale as LanguagesEnum,
                  userProfile.firstName
                )}
              </div>
            </div>
          }
        />
      );
    }

    if (!userProfile && !tokenExists) {
      return (
        <>
          <SecondaryNavItem href={StaticLinks.Login}>
            {t('logIn')}
          </SecondaryNavItem>
          <VerticalDivider />
          <SecondaryNavItem href={StaticLinks.Register}>
            {t('signUp')}
          </SecondaryNavItem>
        </>
      );
    }

    return <div className="w-[130px]" />;
  };

  return (
    <div
      className={twMerge(
        // eslint-disable-next-line max-len
        'bg-secondary-500 hidden h-10 items-center justify-start px-4 lg:px-[60px] lg:flex'
      )}
    >
      {/* // TODO: Uncomment after implementation */}
      {/* {userProfile && (
        <div className="hidden lg:flex">
          <SecondaryNavItem href="/become-supplier">
            {t('becomeSupplier')}
          </SecondaryNavItem>
        </div>
      )} */}
      <div className="ml-auto flex items-center gap-x-6">
        <div className="flex items-center gap-x-4">
          <LanguageSelector
            variant="topnavbar"
            onChange={(lang) =>
              handleLanguageSwitch(lang, category, product, page, altPage)
            }
          />
          <VerticalDivider />
          {/*TODO: Uncomment after implementation */}
          {/* <SecondaryNavItem href={StaticLinks.Help}>
            <ChatIcon /> {t('getHlp')}
          </SecondaryNavItem>
          <VerticalDivider /> */}
          <CurrentOrdersNavItem>
            <VerticalDivider />
          </CurrentOrdersNavItem>
          {resolveUserSection()}
        </div>
      </div>
    </div>
  );
};
