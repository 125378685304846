import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type AvatarSizes = 'sm' | 'md';

const avatarSizesDict: Record<AvatarSizes, string> = {
  sm: 'h-5 w-5 ',
  md: 'h-9 w-9',
};

interface IAvatarCircleProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  size?: AvatarSizes;
}

export const AvatarCircle = ({
  name,
  className,
  size = 'md',
  ...rest
}: IAvatarCircleProps) => {
  const avatarSize = avatarSizesDict[size];

  return (
    <div
      {...rest}
      className={twMerge(
        'flex shrink-0 items-center justify-center',
        'rounded-full font-semibold uppercase text-white',
        'bg-default-primary',
        avatarSize,
        className
      )}
    >
      {name ? name.charAt(0) : ''}
    </div>
  );
};
