import { Fragment, useRef } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { CategoryTreeItem } from 'epromo-types/Inventory';
import { Language, ProductContract } from 'epromo-types';
import { useSwiperMenu } from 'epromo-lib/hooks/useSwiperMenu';
import { useApp } from 'epromo-lib/hooks/useApp';
import { useMediaQuery } from 'epromo-lib/hooks/useMediaQuery';
import { screens } from 'epromo-lib/utils/tailwindTheme';
import { useAppStateTrackedStore } from 'epromo-lib/store/app';

import { NavigationMobileBaseContent } from './NavigationMobileBaseContent';
import { NavigationMobileHeader } from './NavigationMobileHeader';
// eslint-disable-next-line max-len
import { NavigationMobileProfileSettingsContent } from './NavigationMobileProfileSettingsContent';

export interface NavigationMobileProps {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (val: boolean) => void;
  category?: CategoryTreeItem;
  product?: ProductContract;
}

interface BaseSlideProps {
  slideToProfileSettings: () => void;
  openProductsDesktop: () => void;
  openProductsMobile: () => void;
  changeLang: (lang: Language) => void;
  setMobileMenuOpen: (val: boolean) => void;
}

function BaseSlide({
  setMobileMenuOpen,
  slideToProfileSettings,
  openProductsDesktop,
  openProductsMobile,
  changeLang,
}: BaseSlideProps) {
  const isMobile = useMediaQuery(`(max-width: ${screens?.lg})`);
  const isTablet = useMediaQuery(`(max-width: ${screens?.xl})`);

  const handleProductsClick = () => {
    if (isTablet && !isMobile) {
      openProductsDesktop();
      return;
    }
    openProductsMobile();
  };

  return (
    <>
      <NavigationMobileHeader onClose={() => setMobileMenuOpen(false)} />
      <NavigationMobileBaseContent
        onProductsClick={handleProductsClick}
        onLanguageChange={changeLang}
        onMenuClick={slideToProfileSettings}
      />
    </>
  );
}

export function NavigationMobile({
  mobileMenuOpen,
  category,
  product,
  setMobileMenuOpen,
}: NavigationMobileProps) {
  const { t } = useTranslation('common');
  const swiperRef = useRef<SwiperRef>(null);
  const {
    setCategoriesMenuOpen,
    setCategoriesDropMenuOpen,
  } = useAppStateTrackedStore()
  const {
    handleLanguageSwitch,
  } = useApp();

  const slideToProfileSettings = () =>
    slideNext(
      <>
        <NavigationMobileHeader
          onBack={slideBack}
          onClose={() => setMobileMenuOpen(false)}
          title={t('profileSettings')}
        />
        <NavigationMobileProfileSettingsContent />
      </>
    );

  const { slideNext, slideBack, slides } = useSwiperMenu(
    swiperRef,
    <BaseSlide
      openProductsDesktop={() => {
        setMobileMenuOpen(false);
        setCategoriesDropMenuOpen(true);
      }}
      openProductsMobile={() => {
        setMobileMenuOpen(false);
        setCategoriesMenuOpen(true);
      }}
      changeLang={(lang: Language) =>
        handleLanguageSwitch(lang, category, product)
      }
      {...{
        setMobileMenuOpen,
        slideToProfileSettings,
      }}
    />
  );

  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 h-full  xl:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-blue-gray-600 fixed inset-0 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className={twMerge(
                  'relative flex w-screen max-w-md',
                  'h-full flex-col bg-neutral-100 focus:outline-none'
                )}
              >
                <div className="h-full overflow-hidden">
                  <Swiper ref={swiperRef} slidesPerView={1} className="h-full">
                    {slides.map((slide) => {
                      return (
                        <SwiperSlide key={slide.slideId} className="h-full">
                          {slide.content}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
