import { HTMLProps, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import { MoreTabContract } from 'epromo-types';

import { StaticLinks } from 'epromo-lib/constants';
import { Endpoints, useGetQuery } from 'epromo-lib';
import { useDeliveryAddress } from 'epromo-lib/hooks/useDeliveryAddress';
import {
  userProfileSelector,
  useUserProfile,
} from 'epromo-lib/store/auth/user-profile';

import { SecondaryNavItem } from '../../molecules/SecondaryNavigation';

export function CurrentOrdersNavItem({ children }: HTMLProps<HTMLDivElement>) {
  const { t } = useTranslation('common');
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;
  const { getAddressId } = useDeliveryAddress();
  const addressId = getAddressId();

  const { data, refetch } = useGetQuery<MoreTabContract>({
    queryKey: ['user-active-orders'],
    endPoint: Endpoints.more,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (isLoggedIn && addressId) {
      refetch();
    }
  }, [isLoggedIn, addressId]);

  if (!isLoggedIn || !addressId || !data?.activeOrders?.length) return null;

  return (
    <>
      <SecondaryNavItem href={StaticLinks.ProfileOrders}>
        <span
          className={clsx('bg-secondary-100 rounded-3xl px-[5px] py-[2px]')}
        >
          <p className="text-secondary-500 text-[10px] font-bold leading-3">
            {data?.activeOrders?.length}
          </p>
        </span>{' '}
        {t('currentOrders')}
      </SecondaryNavItem>
      {children}
    </>
  );
}
