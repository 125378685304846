import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getSupportedLanguages } from 'epromo-lib/utils/language';
import { Language } from 'epromo-types';

import { Icon } from '@components/atoms/Icon/Icon';

type Variant = 'topnavbar' | 'sidebar';

type LanguageSelectorProps = {
  onChange: (lang: Language) => void;
  variant?: Variant;
};

export function LanguageSelector({
  onChange,
  variant = 'sidebar',
}: LanguageSelectorProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const languages = getSupportedLanguages(t);
  const activeLanguage =
    languages.find((lang) => lang.code === router.locale) || languages[0];

  return (
    <Menu as="div" className="relative z-50 inline-block text-left">
      <div>
        <Menu.Button
          className={clsx(
            'inline-flex w-full items-center',
            'justify-center rounded-md',
            'hover:bg-opacity-30 focus:outline-none focus-visible:ring-2',
            'focus-visible:ring-white focus-visible:ring-opacity-75',
            variant === 'topnavbar'
              ? 'py-2 text-xs uppercase text-white'
              : 'text-black'
          )}
        >
          {languages.map((lang) => (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              alt={lang.code}
              src={lang.flag}
              className={clsx(
                'mx-2 ',
                variant === 'topnavbar' ? 'h-4 w-4' : ' h-6 w-6',
                activeLanguage?.code !== lang.code && 'hidden'
              )}
              key={lang.code}
            />
          ))}
          {activeLanguage?.name}
          {variant !== 'topnavbar' && (
            <Icon name="chevronDown" className="mx-2" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            'absolute z-20 mt-2 w-48',
            variant === 'topnavbar' ? 'right-0' : 'left-0',
            'origin-top-right rounded-md bg-white',
            'shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}
        >
          {languages.map((lang) => (
            <div className="px-1 py-1" key={lang.code}>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className="inline-flex items-center p-1 text-left"
                    type="button"
                    onClick={() => {
                      onChange(lang);
                    }}
                  >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      alt={lang.code}
                      src={lang.flag}
                      className="mr-2 h-6 w-6"
                    />
                    {lang.name}
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
