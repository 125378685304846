import { twMerge } from 'tailwind-merge';

import { ProductContract } from 'epromo-types';
import {
  useUserProfile,
  userProfileSelector,
} from 'epromo-lib/store/auth/user-profile';
import { imageFallbackUrl, imageThumbFallbackUrl } from 'epromo-lib/constants';

import { Img } from '@components/molecules/Img';

const SIZE: Record<keyof typeof SIZE_DIMENSIONS, string> = {
  card: 'h-36 w-full',
  details: 'h-80 w-full',
  swiper: 'h-28 w-auto',
  thumb: 'h-16 w-16',
  searchThumb: 'h-14 w-14',
};

const SIZE_DIMENSIONS: Record<string, { height: number; width?: number }> = {
  card: { height: 144 },
  details: { height: 320 },
  swiper: { height: 112 },
  thumb: { height: 64, width: 64 },
  searchThumb: { height: 56, width: 56 },
};

const NO_IMAGE_SIZE: Record<keyof typeof SIZE_DIMENSIONS, string> = {
  card: 'h-10 w-full',
  details: 'h-40 w-full',
  swiper: 'h-14 w-auto',
  thumb: 'h-8 w-full',
  searchThumb: 'h-8 w-full',
};

export interface ProductImageProps {
  variant?: keyof typeof SIZE_DIMENSIONS;
  image: ProductContract['image'];
  name: ProductContract['name'];
  inStock?: ProductContract['inStock'];
  className?: string;
  checkInStock?: boolean;
}

export function ProductImage({
  variant = 'thumb',
  image,
  name,
  inStock = true,
  className,
  checkInStock = false,
}: ProductImageProps) {
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;

  const fallbackUrl =
    variant === 'thumb' ? imageThumbFallbackUrl : imageFallbackUrl;

  if (!image) {
    return (
      <div
        className="flex items-center justify-center"
        style={{
          height: SIZE_DIMENSIONS[variant].height,
          width: SIZE_DIMENSIONS[variant].width ?? 'auto',
        }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img alt={name} src={fallbackUrl} className={NO_IMAGE_SIZE[variant]} />
      </div>
    );
  }

  return (
    <Img
      url={image}
      alt={name}
      fetchPriority="high"
      height={SIZE_DIMENSIONS[variant].height}
      width={SIZE_DIMENSIONS[variant].width}
      className={twMerge(
        'rounded-t-lg object-contain object-center',
        'w-auto select-none',
        SIZE[variant],
        checkInStock && isLoggedIn && !inStock && 'opacity-30 grayscale',
        className
      )}
    />
  );
}

ProductImage.displayName = 'ProductImage';
