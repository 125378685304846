import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { Language } from 'epromo-types';
import { StaticLinks } from 'epromo-lib/constants';
import { useFavorites } from 'epromo-lib/hooks/useFavorites';
import { useCredentialsLogin } from 'epromo-lib/hooks/useCredentialsLogin';
import {
  userProfileSelector,
  useUserProfile,
} from 'epromo-lib/store/auth/user-profile';

import { Icon } from '@components/atoms/Icon/Icon';
import { ButtonAppearance } from '@components/atoms/Button';
import { UtilityText } from '@components/atoms/UtilityText';
import { ButtonLink } from '@components/atoms/ButtonLink';
import { NavLink } from '@components/atoms/NavLink';

import UserIcon from '@components/atoms/assets/icons/user.svg';
import { LanguageSelector } from '@components/organisms/LanguageSelector';
// eslint-disable-next-line max-len
import { SelectDeliveryAddressDialog } from '@components/organisms/SelectDeliveryAddressDialog';

export type MenuType = 'profileSettings';

export type NavigationMobileProps = {
  onProductsClick: () => void;
  onLanguageChange: (lang: Language) => void;
  onMenuClick: (type: MenuType) => void;
};

export const NavigationMobileBaseContent = ({
  onProductsClick,
  onLanguageChange,
  onMenuClick,
}: NavigationMobileProps) => {
  const { t } = useTranslation('common');
  const { logout } = useCredentialsLogin();
  const { selectedDeliveryAddress } = useUserProfile();
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const { productsWishlistMap } = useFavorites();

  return (
    <>
      <div
        className={twMerge(
          'flex h-[calc(100%_-_70px)] flex-col',
          'overflow-x-hidden overflow-y-scroll'
        )}
      >
        <div className="mt-4 px-4">
          <button
            type="button"
            className={twMerge(
              isLoggedIn ? 'bg-skin-button-default' : 'bg-secondary-500',
              'w-full rounded-xl px-4 py-6 text-white',
              'flex items-center justify-between'
            )}
            onClick={onProductsClick}
          >
            <span className="flex items-center gap-x-2">
              <Icon name="categories" />
              {t('allProducts')}
            </span>
            <Icon name="chevronRight" />
          </button>
        </div>
        {isLoggedIn && (
          <div className={twMerge('bg-white', 'rounded-xl', 'mx-4 mt-4')}>
            <ul role="list" className="divide-y divide-neutral-100">
              {isLoggedIn && selectedDeliveryAddress && (
                <li className="flex items-center px-4 py-4">
                  <button
                    onClick={() => {
                      setIsAddressDialogOpen(true);
                    }}
                    type="button"
                    className={twMerge(
                      'w-full rounded-xl text-white',
                      'flex items-center justify-between'
                    )}
                  >
                    <span
                      className={twMerge(
                        'flex w-full items-center',
                        'gap-x-2 text-black'
                      )}
                    >
                      <Icon name="mapOutline" />
                      <span className="relative min-w-0 flex-1">
                        <span
                          className={twMerge(
                            'flex min-w-0 flex-col items-start',
                            'overflow-hidden text-clip',
                            'whitespace-nowrap'
                          )}
                        >
                          {/* eslint-disable-next-line max-len */}
                          <UtilityText>
                            {selectedDeliveryAddress?.holderName}
                          </UtilityText>
                          <p className="text-left text-sm font-medium">
                            {selectedDeliveryAddress.address}
                          </p>
                        </span>
                        <span
                          className={twMerge(
                            'absolute bottom-0 right-0',
                            'h-12 w-10 bg-gradient-to-r',
                            'from-transparent to-white'
                          )}
                        />
                      </span>
                      <Icon name="chevronRight" className="text-black" />
                    </span>
                  </button>
                </li>
              )}
              <li className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex-shrink-0 text-black">
                  <UserIcon />
                </div>
                <div
                  className={twMerge('min-w-0 flex-1 px-3')}
                  onClick={() => onMenuClick('profileSettings')}
                >
                  {t('profile')}
                </div>
              </li>
              <li>
                <NavLink
                  href={`${StaticLinks.ProfileOrders}?repeatOrderLayout=true`}
                  className="flex items-center px-4 py-4 sm:px-6"
                >
                  <div className="flex-shrink-0">
                    <Icon name="reorder" />
                  </div>
                  <div className={twMerge('min-w-0 flex-1 px-2')}>
                    {t('reorder')}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  href={StaticLinks.Wishlist}
                  className="flex items-center px-4 py-4 sm:px-6"
                >
                  <div className="flex-shrink-0">
                    <Icon
                      name={
                        Object.keys(productsWishlistMap)?.length
                          ? 'heartFilled'
                          : 'heart'
                      }
                    />
                  </div>
                  <div className={twMerge('min-w-0 flex-1 px-2')}>
                    {t('wishlist')}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  href={StaticLinks.CartTemplates}
                  className="flex items-center px-4 py-4 sm:px-6"
                >
                  <div className="flex-shrink-0">
                    <Icon name="list" />
                  </div>
                  <div className={twMerge('min-w-0 flex-1 px-2')}>
                    {t('shopCartTmpl')}
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        <div className={twMerge('bg-white', 'mx-4 my-4', 'rounded-xl')}>
          <ul role="list" className="divide-y divide-neutral-100">
            <li className="-mb-2 flex items-center px-4 py-4">
              <LanguageSelector onChange={onLanguageChange} />
            </li>
            {/* TODO: Uncomment after implementation: */}
            {/* <li>
              <NavLink
                href={StaticLinks.Help}
                className="flex items-center px-4 py-4 sm:px-6"
              >
                <div className="flex-shrink-0">
                  <Icon name="chatOutlined" className="text-white" />
                </div>
                <div className={clsx('min-w-0 flex-1 px-2')}>
                  {t('getHlp')}
                </div>
              </NavLink>
            </li> */}

            {/* // TODO: Uncomment after implementation */}
            {/* <li className="flex items-center px-4 py-4 sm:px-6">
              <div className="flex-shrink-0">
                <Icon name="userPlus" />
              </div>
              <div className={clsx('min-w-0 flex-1 px-2')}>
                {t('becomeSupplier')}
              </div>
            </li> */}
          </ul>
        </div>
        {isLoggedIn && (
          <div className={twMerge('bg-white', 'rounded-xl', 'mx-4 mb-4')}>
            <ul role="list" className="divide-y divide-neutral-100">
              <li className="flex items-center px-4 py-4">
                <button
                  onClick={() => logout()}
                  type="button"
                  className={twMerge(
                    'text-negative-500 w-full',
                    'flex items-center justify-between'
                  )}
                >
                  <span className="flex items-center gap-x-2">
                    <Icon name="logout" />
                    {t('logOut')}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        )}
        {!isLoggedIn && (
          <div
            className={twMerge(
              'flex flex-1 flex-shrink-0 flex-col',
              'justify-end gap-y-4 px-4 py-4'
            )}
          >
            <ButtonLink
              appearance={ButtonAppearance.PRIMARY}
              fluid
              href={StaticLinks.Login}
            >
              {t('logIn')}
            </ButtonLink>
            <ButtonLink
              appearance={ButtonAppearance.SECONDARY}
              fluid
              href={StaticLinks.Register}
            >
              {t('signUp')}
            </ButtonLink>
          </div>
        )}
      </div>
      <SelectDeliveryAddressDialog
        isOpened={isAddressDialogOpen}
        onClose={() => {
          setIsAddressDialogOpen(false);
        }}
      />
    </>
  );
};
