import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { Endpoints, useGetQuery } from 'epromo-lib';
import { getAddressId } from 'epromo-lib/hooks/useDeliveryAddress';
import { CompanyDetailsContract, MoreTabContract } from 'epromo-types';

import { Price } from '@components/atoms/Price';

type CreditBalanceBoxVariant = 'gray' | 'white';
const bgDict: Record<CreditBalanceBoxVariant, string> = {
  gray: 'bg-neutral-100',
  white: 'bg-white',
};

export const CreditBalanceBox = ({
  variant = 'gray',
  size = 'default',
}: {
  variant?: CreditBalanceBoxVariant;
  size?: 'default' | 'lg';
}) => {
  const { t } = useTranslation('common');
  const { data: companyDetails } = useGetQuery<CompanyDetailsContract>({
    queryKey: [`companyDetails`, getAddressId()],
    endPoint: Endpoints.morePurchaseConditions,
  });

  const { data: userActiveOrders, isFetched } = useGetQuery<MoreTabContract>({
    queryKey: ['user-active-orders', getAddressId()],
    endPoint: Endpoints.more,
  });

  // eslint-disable-next-line max-len
  const managerStr = `${userActiveOrders?.manager?.name ?? ''} ${
    userActiveOrders?.manager?.phoneNumber ?? ''
  }`;

  const balance = companyDetails?.creditBalance ?? 0;
  const limit = companyDetails?.creditLimit ?? 0;

  const percentage = (balance / limit) * 100;
  const progressValue = percentage > 100 ? 100 : percentage;

  return (
    <div
      className={twMerge(
        'flex flex-col rounded-xl',
        'p-3 text-xs text-neutral-700',
        bgDict[variant],
        size === 'lg' && 'p-4 sm:p-8'
      )}
    >
      <div className="flex flex-row pb-1 align-middle">
        <div
          className={twMerge(
            'pr-1 font-bold',
            size === 'lg' && 'text-label text-xs sm:text-xl'
          )}
        >
          {t('creditBalance')}:
        </div>
        <Price
          price={balance}
          className={twMerge(
            'text-xs font-normal text-neutral-700',
            size === 'lg' && 'text-label text-xs sm:text-xl'
          )}
        />
      </div>

      <div
        className={twMerge(
          'mb-3 h-1 w-full rounded-full bg-neutral-200',
          size === 'lg' && 'mb-2 h-2 sm:mb-3'
        )}
      >
        <div
          className="h-full rounded-full bg-green-500 "
          style={{ width: `${progressValue}%` }}
        />
      </div>

      <div
        className={twMerge(
          'flex flex-row pb-3',
          size === 'lg' && 'pb-0 text-xs sm:text-sm'
        )}
      >
        <div className="pr-1 font-bold">{t('creditLimit')}:</div>
        <Price
          price={limit}
          className={twMerge(
            'text-neutral-700" text-xs font-normal',
            size === 'lg' && 'pb-0 text-xs sm:text-sm'
          )}
        />
      </div>

      {size !== 'lg' && (
        <>
          <div className={twMerge(!isFetched && 'pb-4', 'font-bold')}>
            {t('manager')}:
          </div>
          {managerStr}
        </>
      )}
    </div>
  );
};
