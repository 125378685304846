import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { useAppStateTrackedStore } from 'epromo-lib/store/app';

import { CategoryTreeItem } from 'epromo-types/Inventory';
import { resolveLocaleProp } from 'epromo-lib/utils/slug';

import { Img } from '@components/molecules/Img';
import { Icon } from '@components/atoms/Icon';

type CategorySlide = {
  items: CategoryTreeItem[];
  title: string;
  prevLevel: number;
  id: string;
  headerImg?: string;
};

type CategoryNavigationMobileProps = {
  isNavOpen: boolean;
  setNavOpen: (val: boolean) => void;
  flatInventoryList?: CategoryTreeItem[] | undefined;
  onNav: (path: string) => void;
  currCategory?: CategoryTreeItem;
  setMobileMenuOpen: (val: boolean) => void;
};

export function CategoryNavigationMobile({
  isNavOpen,
  setNavOpen,
  onNav,
  currCategory,
  setMobileMenuOpen,
  flatInventoryList,
}: CategoryNavigationMobileProps) {
  const { setCategoriesMenuOpen } = useAppStateTrackedStore();
  const swiperRef = useRef<SwiperRef>(null);
  const router = useRouter();
  const localeProp = resolveLocaleProp(router.locale);
  const { t } = useTranslation('common');
  const [slides, setSlides] = useState<CategorySlide[]>([]);

  useEffect(() => {
    generateInitialSlides();
  }, [flatInventoryList, currCategory]);

  useEffect(() => {
    if (!flatInventoryList) {
      return;
    }
    swiperRef.current?.swiper?.slideTo(slides.length);
  }, [slides]);

  const generateInitialSlides = () => {
    const firstSlide = {
      prevLevel: 0,
      title: t('products'),
      items: (flatInventoryList || []).filter((x) => x.level === 1),
      id: 'products',
    };
    const newSlides = generateSlides(currCategory, flatInventoryList);
    setSlides([firstSlide, ...newSlides]);
  };

  const generateSlides = (
    category?: CategoryTreeItem,
    inventoryList?: CategoryTreeItem[]
  ): CategorySlide[] => {
    let slides: CategorySlide[] = [];
    let parent: CategoryTreeItem | undefined = inventoryList?.find(
      (item) => item.id === category?.parent
    );
    if (!parent || !category || !inventoryList) {
      return [];
    }
    slides = Array.from({ length: category.level - 1 }, () => {
      if (!parent) return null;
      const slide = generateSlide(parent);
      parent = inventoryList?.find((item) => item.id === parent?.parent);
      return slide;
    })
      .reverse()
      .filter((item) => item !== null) as CategorySlide[];
    return slides;
  };

  const generateSlide = (category: CategoryTreeItem) => {
    const firstItem: CategoryTreeItem[] = [];
    if (category.level >= 1 || typeof category.level === 'undefined') {
      firstItem.push({
        ...category,
        isFirst: true,
        name: t('allProducts'),
      });
    }
    return {
      prevLevel: category.level,
      title: category.name,
      items: [...firstItem, ...category.childCategories],
      id: `slide-${category.id}`,
      headerImg: category.level === 1 ? category?.headerImage : undefined,
    };
  };

  const goBackOneLevel = () => {
    if (slides.length > 1) {
      setSlides([...slides.slice(0, -1)]);
    } else {
      setMobileMenuOpen(true);
      setCategoriesMenuOpen(false);
    }
  };

  const handleCategoryClick = (item: CategoryTreeItem) => {
    if (item.isFirst || (item.childCategories || []).length === 0) {
      onNav(`${item[localeProp]}`);
      return;
    }
    setSlides([...slides, generateSlide(item)]);
  };

  const handleCloseClick = () => {
    setNavOpen(false);
    setMobileMenuOpen(false);
  };

  return (
    <>
      <Transition.Root show={isNavOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 xl:hidden"
          onClose={setNavOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-blue-gray-600 fixed inset-0 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className={clsx(
                  'relative flex w-screen max-w-md',
                  'flex-col bg-neutral-100 focus:outline-none'
                )}
              >
                <div className="flex min-h-0 flex-1 flex-col overflow-hidden">
                  <div className="overflow-hidden">
                    {flatInventoryList && (
                      <Swiper
                        slidesPerView={1}
                        ref={swiperRef}
                        className="h-screen bg-neutral-100"
                        initialSlide={slides.length}
                      >
                        {slides.map((slide) => (
                          <SwiperSlide key={slide.id}>
                            <div
                              className={clsx(
                                'flex h-[70px] px-4',
                                'flex-shrink-0 items-center justify-between',
                                'relative z-10 bg-white drop-shadow-md'
                              )}
                            >
                              <button
                                type="button"
                                className={clsx(
                                  'flex gap-x-2',
                                  'focus:outline-none focus:ring-2',
                                  'focus:ring-inset focus:ring-white',
                                  slide.prevLevel === 1 && 'font-bold'
                                )}
                                onClick={goBackOneLevel}
                              >
                                <Icon name="arrowLeft" />
                                <span>{slide.title}</span>
                              </button>

                              <button
                                type="button"
                                className={clsx(
                                  'flex',
                                  'focus:outline-none focus:ring-2',
                                  'focus:ring-inset focus:ring-white'
                                )}
                                onClick={handleCloseClick}
                              >
                                <span className="sr-only">Close sidebar</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>

                            <div
                              className={clsx(
                                'relative flex flex-col',
                                'gap-y-1 overflow-y-scroll',
                                'h-[calc(100vh_-_70px)] pb-24',
                                !slide.headerImg ? 'pt-2' : 'pt-20'
                              )}
                            >
                              {slide.headerImg ? (
                                <div className="absolute top-0 right-0 -z-10">
                                  <div
                                    className={clsx('h-24 w-full', 'relative')}
                                  >
                                    <Img
                                      url={slide?.headerImg}
                                      alt={slide.title}
                                      className="h-24 w-full object-cover"
                                      height={96}
                                    />
                                    <div
                                      className={clsx(
                                        'absolute top-0 h-24 w-full',
                                        'bg-gradient-to-b from-transparent',
                                        'to-neutral-100'
                                      )}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {slide.items.map((item) => {
                                const selected = currCategory?.id === item.id;
                                return (
                                  <button
                                    key={item.id}
                                    onClick={() => handleCategoryClick(item)}
                                    className={clsx(
                                      'mx-4 px-4 py-3.5',
                                      'text-left',
                                      'flex items-center gap-x-2',
                                      'rounded-lg bg-white',
                                      selected && 'text-business-500',
                                      item.isFirst &&
                                        'text-business-500 font-bold'
                                    )}
                                  >
                                    {item.level === 1 && !item.isFirst && (
                                      <img
                                        src={item.webIcon}
                                        alt={item.name}
                                        className="h-6 w-6 flex-shrink-0"
                                      />
                                    )}
                                    <span>{item.name}</span>
                                    {selected ? (
                                      <span
                                        className={clsx(
                                          'flex',
                                          'flex-grow justify-end'
                                        )}
                                      >
                                        <Icon name="checkmark" className="" />
                                      </span>
                                    ) : !item.isFirst ? (
                                      <span
                                        className={clsx(
                                          'flex',
                                          'flex-grow justify-end'
                                        )}
                                      >
                                        <Icon
                                          name="chevronRight"
                                          className=""
                                        />
                                      </span>
                                    ) : null}
                                  </button>
                                );
                              })}
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
