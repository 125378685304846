import { ReactNode, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/router';

import {
  IDeviceInfo,
  NativeAppNotificationStatus,
  ProductContract,
} from 'epromo-types';
import {
  IGlobalNotificationDoc,
  IInnerPage,
} from 'epromo-lib-cms/types/payload-types';
import { CategoryTreeItem } from 'epromo-types/Inventory';

import { useAppStateTrackedStore } from 'epromo-lib/store/app';


import { screens } from 'epromo-lib/utils/tailwindTheme';
import { useInitialCategories } from 'epromo-lib/hooks/useInitialCategories';
import { useMediaQuery } from 'epromo-lib/hooks/useMediaQuery';

// eslint-disable-next-line max-len
import { MobileAppSuggestionDialog } from '@components/molecules/MobileAppSuggestionDialog';
import { SecondaryNavigation } from '@components/molecules/SecondaryNavigation';
import { PrimaryNavigation } from '@components/organisms/PrimaryNavigation';
import { NavigationMobile } from '@components/organisms/NavigationMobile';
// eslint-disable-next-line max-len
import { CategoryNavigationMobile } from '@components/organisms/CategoryNavigationMobile';
// eslint-disable-next-line max-len
import { ProductsSearchMobile } from '@components/organisms/ProductsSearchMobile';

import { GlobalNotificationsBar } from '../GlobalNotificationsBar';

export type HeaderNavigationProps = {
  isSticky: boolean;
  product?: ProductContract;
  category?: CategoryTreeItem;
  flatInventoryList?: CategoryTreeItem[] | undefined;
  renderMiniCartTotals?: () => ReactNode;
  page?: IInnerPage;
  altPage?: IInnerPage;
  isLoading: boolean;
  tokenExists: boolean;
  deviceInfo?: IDeviceInfo;
  notificationToDisplay?: IGlobalNotificationDoc;
  closedNotifications?: string[];
  nativeAppNotificationStatus?: string;
};

export function HeaderNavigation({
  isSticky,
  product,
  category,
  renderMiniCartTotals,
  page,
  altPage,
  tokenExists,
  deviceInfo,
  notificationToDisplay,
  closedNotifications = [],
  nativeAppNotificationStatus,
}: HeaderNavigationProps) {
  const isMobileSsr = deviceInfo?.isMobile || deviceInfo?.isTablet;
  const { flatInventoryList, refetch } = useInitialCategories(!isMobileSsr);
  const router = useRouter();
  const routerPath = router.asPath;
  const isTabletView = useMediaQuery(`(max-width: ${screens?.md})`);
  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    categoriesMenuOpen,
    setCategoriesMenuOpen,
  } = useAppStateTrackedStore();

  const { closeSearch, searchInputHasFocus, setSearchInputHasFocus } =
    useAppStateTrackedStore();

  useEffect(() => {
    closeSearch();
    setSearchInputHasFocus(false);
  }, [routerPath, category]);

  useEffect(() => {
    if (isMobileSsr && categoriesMenuOpen) {
      refetch();
    }
  }, [categoriesMenuOpen]);

  const pageChangeHandler = () => {
    setMobileMenuOpen(false);
  }

  useEffect(() => {
    router.events.on('routeChangeStart', pageChangeHandler);

    return () => {
      router.events.off('routeChangeStart', pageChangeHandler);

    };
  }, []);

  const levelOneCategories = flatInventoryList?.filter(
    (cat) => cat.level === 1
  );

  const nativeAppNotificationIsClosed =
    nativeAppNotificationStatus === NativeAppNotificationStatus.Closed;

  return (
    <>
      {isMobileSsr && (
        <MobileAppSuggestionDialog
          deviceInfo={deviceInfo}
          nativeAppNotificationStatus={nativeAppNotificationStatus}
        />
      )}
      <header
        id="header"
        className={twMerge(isSticky && 'sticky top-0',
          'relative z-50')}
      >
        <nav aria-label="Top">
          <GlobalNotificationsBar
            notificationToDisplay={notificationToDisplay}
            closedNotifications={closedNotifications}
          />
          {!isMobileSsr && (
            <SecondaryNavigation
              product={product}
              category={category}
              page={page}
              altPage={altPage}
              tokenExists={tokenExists}
            />
          )}
          <PrimaryNavigation
            flatInventoryList={levelOneCategories}
            currCategory={category}
            renderMiniCartTotals={renderMiniCartTotals}
            isGlobalNotificationVisible={!!notificationToDisplay}
            nativeAppNotificationIsClosed={nativeAppNotificationIsClosed}
            tokenExists={tokenExists}
            deviceInfo={deviceInfo}
          />
          {mobileMenuOpen && (
            <NavigationMobile
              mobileMenuOpen={mobileMenuOpen}
              product={product}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          )}
        </nav>
        <CategoryNavigationMobile
          onNav={(path) => {
            setCategoriesMenuOpen(false);
            setMobileMenuOpen(false);
            router.push(path);
          }}
          isNavOpen={categoriesMenuOpen}
          setNavOpen={setCategoriesMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          flatInventoryList={flatInventoryList}
          currCategory={category}
        />
        {searchInputHasFocus && (
          <ProductsSearchMobile
            mobileMenuOpen={searchInputHasFocus && isTabletView}
            setMobileMenuOpen={closeSearch}
          />
        )}
      </header>
    </>
  );
}
