import clsx from 'clsx';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type UtilityTextProps = {
  className?: string;
  children: ReactNode[] | ReactNode | string;
};

export function UtilityText({ children, className }: UtilityTextProps) {
  if (!children) {
    return null;
  }
  return (
    <p
      className={twMerge(
        clsx('text-sm font-normal text-neutral-500', className)
      )}
    >
      {children}
    </p>
  );
}
