import React, {
  Fragment,
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import debounce from 'lodash.debounce';
import { useTranslation } from 'next-i18next';
import { InView } from 'react-intersection-observer';
import { useRouter } from 'next/router';

import { useAppState } from 'epromo-lib/store/app';
import { useSearchProducts } from 'epromo-lib/hooks/useSearchProducts';
import { useMediaQuery } from 'epromo-lib/hooks/useMediaQuery';
import { useUserSearch } from 'epromo-lib/store/search';
import {
  userProfileSelector,
  useUserProfile,
} from 'epromo-lib/store/auth/user-profile';
import { screens } from 'epromo-lib/utils/tailwindTheme';
import { resolveUrl } from 'epromo-lib/utils/slug';

import {
  SearchCategoryLine,
  SearchLoader,
  SearchNoResults,
  SearchProductLine,
} from '@components/organisms/SearchProducts';
import { TextInput } from '@components/atoms/TextInput';
import { A } from '@components/atoms/A';
import { BackTopButton } from '@components/atoms/BackTopButton';
import { LoadMore } from '@components/molecules/LoadMore';
import { Icon } from '@components/atoms/Icon/Icon';

type ProductsSearchMobileProps = {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (val: boolean) => void;
};

export function ProductsSearchMobile({
  mobileMenuOpen,
  setMobileMenuOpen,
}: ProductsSearchMobileProps) {
  const { locale } = useRouter();
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;
  const { searchInput, setSearchInput, closeSearch, setSearchInputHasFocus } =
    useAppState();
  const { t } = useTranslation('common');
  const { products } = useUserSearch();
  const [debouncedSearch, setDebouncedSearch] = useState<string | undefined>();
  const isTabletView = useMediaQuery(`(max-width: ${screens?.md})`);
  const {
    categories,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetched,
    data,
  } = useSearchProducts(debouncedSearch, !!debouncedSearch && isTabletView);
  const [btnInView, setBtnInView] = useState(false);
  const hasData = (data?.pages?.[0]?.products?.length ?? 0) > 0;
  const { setCategoriesMenuOpen } = useAppState();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const debouncedChange = useCallback(debounce(setDebouncedSearch, 800), []);

  useEffect(() => {
    debouncedChange(searchInput);
  }, [searchInput]);

  const handleClose = () => {
    setSearchInput('');
    closeSearch();
    setSearchInputHasFocus(false);
  };

  return (
    <>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-blue-gray-600 fixed inset-0 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                className={twMerge(
                  'relative flex w-screen',
                  'flex-col bg-neutral-100 focus:outline-none'
                )}
              >
                <div className="flex min-h-0 flex-1 flex-col ">
                  <div
                    className={twMerge(
                      'flex h-[70px] gap-x-4 px-4',
                      'flex-shrink-0 items-center justify-between',
                      'mb-2 bg-white drop-shadow-md',
                      'sticky top-0'
                    )}
                  >
                    <button
                      type="button"
                      className="outline-none"
                      onClick={handleClose}
                    >
                      <Icon name="chevronRight" className="rotate-180" />
                    </button>
                    <div className="relative w-full">
                      <div
                        className={twMerge(
                          'pointer-events-none absolute inset-y-0',
                          'text-secondary-500 left-4 z-40',
                          'flex items-center'
                        )}
                      >
                        <MagnifyingGlassIcon className="h-5 w-5" />
                      </div>
                      {searchInput && (
                        <button
                          type="button"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            setSearchInput('');
                            searchInputRef?.current?.focus();
                          }}
                          className={twMerge(
                            'absolute inset-y-0',
                            'text-secondary-500 right-4 z-40'
                          )}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      )}
                      <TextInput
                        ref={searchInputRef}
                        autoComplete="off"
                        name="search"
                        className={twMerge(
                          'flex w-full rounded-3xl px-10',
                          'transition-all duration-150 ease-in-out',
                          'text-base'
                        )}
                        value={searchInput}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {isLoading && !isFetchingNextPage && (
                    <div className="-mt-6 px-4">
                      <SearchLoader isLoading={isLoading} />
                    </div>
                  )}
                  <div
                    id="mobile-search-container"
                    className="overflow-y-auto px-4"
                  >
                    {!isLoading && !debouncedSearch && products.length > 0 && (
                      <div>
                        <p
                          className={twMerge(
                            'text-secondary-500',
                            'py-2 text-center text-sm font-medium'
                          )}
                        >
                          {t('youRecentlySearched')}
                        </p>
                        {products.map((product) => (
                          <A
                            key={product.id}
                            href={resolveUrl(locale, product) || ''}
                          >
                            <SearchProductLine
                              key={product.id}
                              isLoggedIn={isLoggedIn}
                              {...product}
                            />
                          </A>
                        ))}
                      </div>
                    )}
                    {!hasData && searchInput && isFetched && (
                      <SearchNoResults
                        searchInput={searchInput}
                        onCta={() => {
                          handleClose();
                          setCategoriesMenuOpen(true);
                        }}
                      />
                    )}
                    <div>
                      {!isLoading &&
                        isFetched &&
                        categories?.map((category) => (
                          <A
                            key={category.id}
                            href={resolveUrl(locale, category) || ''}
                          >
                            <SearchCategoryLine
                              key={category.id}
                              isLoggedIn={isLoggedIn}
                              {...category}
                            />
                          </A>
                        ))}
                      {data?.pages.map((page, pageIdx) => (
                        <div key={page?.currentPage}>
                          {page?.products?.map((product, pIdx) => (
                            <Fragment key={product.id}>
                              {pageIdx === 0 && pIdx === 0 ? (
                                <InView
                                  rootMargin="0px 0px 0px 0px"
                                  onChange={(isInView) => {
                                    setBtnInView(isInView);
                                  }}
                                >
                                  <A href={resolveUrl(locale, product) || ''}>
                                    <SearchProductLine
                                      isLoggedIn={isLoggedIn}
                                      {...product}
                                    />
                                  </A>
                                </InView>
                              ) : (
                                <A href={resolveUrl(locale, product) || ''}>
                                  <SearchProductLine
                                    isLoggedIn={isLoggedIn}
                                    {...product}
                                  />
                                </A>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                    {hasData && (
                      <InView
                        rootMargin="400px 0px 400px 0px"
                        onChange={() => {
                          fetchNextPage();
                        }}
                      >
                        {data && hasNextPage && (
                          <LoadMore
                            onLoadMore={() => {
                              fetchNextPage();
                            }}
                            isLoading={isLoading || isFetchingNextPage}
                          />
                        )}
                      </InView>
                    )}
                  </div>
                </div>
                {hasData && isFetched && (
                  <BackTopButton
                    onClick={() => {
                      document
                        .getElementById('mobile-search-container')
                        ?.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    btnInView={btnInView}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
