import { useTranslation } from 'next-i18next';

import { TareContract } from 'epromo-types';

import { HelperText } from '../HelperText';

export type TarePriceProps = {
  tare?: TareContract;
};

export function TarePrice({ tare }: TarePriceProps) {
  const { t } = useTranslation('common');
  if (!tare || !tare.tarePrice) {
    return null;
  }

  return (
    <p className="flex gap-1 align-baseline text-xs">
      <HelperText text={t('deposit')} />
      <p className="font-bold">+{tare.tarePrice.toFixed(2)}</p>
    </p>
  );
}
