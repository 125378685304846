import { MenuLink, StaticLinks } from 'epromo-lib/constants';

export const baseUserMenuLinks: MenuLink[] = [
  {
    href: StaticLinks.Profile,
    transKey: 'profileSettings',
    iconName: 'user',
    innerLinks: [
      {
        href: StaticLinks.Profile,
        transKey: 'editProfile',
      },
      {
        href: StaticLinks.DeliveryAddresses,
        transKey: 'deliveryAddr',
      },
    ],
  },
  {
    href: StaticLinks.ProfileClientCard,
    transKey: 'clientCard',
    iconName: 'cardClient',
  },
  {
    href: StaticLinks.ProfileOrders,
    transKey: 'orders',
    iconName: 'shoppingBasket',
  },
];

export const b2cUserMenuLinks: MenuLink[] = [
  {
    href: StaticLinks.ProfilePaymentMethodB2C,
    transKey: 'paymentMethod',
    iconName: 'cardPayment',
  },
  {
    href: StaticLinks.InvoiceRecipients,
    transKey: 'vatInvoiceRecipients',
    iconName: 'invoice',
  },
];

export const b2bUserMenuLinks: MenuLink[] = [
  {
    href: StaticLinks.ProfilePaymentMethodB2B,
    transKey: 'paymentMethod',
    iconName: 'cardPayment',
  },
  {
    href: StaticLinks.ProfileInvoices,
    transKey: 'invoicesPayment',
    iconName: 'invoice',
  },
  {
    href: StaticLinks.ProfileOtherDocuments,
    transKey: 'otherDocuments',
    iconName: 'document',
  },
  {
    href: StaticLinks.ProfilePurchaseConditions,
    transKey: 'purchaseConditions',
    iconName: 'info',
  },
  // TODO: Uncomment after implementation from api.
  // {
  //   href: StaticLinks.ProfileEmployeeManagement,
  //   transKey: 'employeeManagement',
  //   iconName: 'users',
  // },
];
