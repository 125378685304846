{
    /* eslint-disable max-len */
}
import { SVGProps } from 'react';

export const EpromoLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={116}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.009 13.578c1.763 0 3.198-.62 4.31-1.858 1.106-1.242 1.68-2.696 1.719-4.367.044-1.664-.453-3.104-1.493-4.313-1.038-1.208-2.328-1.811-3.869-1.811h-8.947v18.552h2.743c1.826 0 2.745-.8 2.745-2.397v-3.806h2.792Zm-1.218-8.656c1.55 0 2.33.71 2.33 2.122 0 1.417-.78 2.126-2.33 2.126h-1.574V4.922h1.574Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.009 13.578c1.763 0 3.198-.62 4.31-1.858 1.106-1.242 1.68-2.696 1.719-4.367.044-1.664-.453-3.104-1.493-4.313-1.038-1.208-2.328-1.811-3.869-1.811h-8.947v18.552h2.743c1.826 0 2.745-.8 2.745-2.397v-3.806h2.792Zm-1.218-8.656c1.55 0 2.33.71 2.33 2.122 0 1.417-.78 2.126-2.33 2.126h-1.574V4.922h1.574ZM51.01 11.831l2.489 3.388c.675.8 1 1.525.974 2.173-.03.65-.362 1.229-.994 1.747-.633.512-1.3.756-2 .733-.7-.024-1.417-.451-2.145-1.29l-4.335-5.899v4.662c0 1.595-.913 2.395-2.74 2.395h-2.742V1.134h8.942c1.531 0 2.807.588 3.826 1.759 1.016 1.17 1.522 2.43 1.522 3.77 0 2.397-.932 4.12-2.798 5.168ZM45 4.883v4.249h1.58c1.55 0 2.323-.711 2.323-2.127 0-1.411-.774-2.122-2.323-2.122H45Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m51.01 11.831 2.489 3.388c.675.8 1 1.525.974 2.173-.03.65-.362 1.229-.994 1.747-.633.512-1.3.756-2 .733-.7-.024-1.417-.451-2.145-1.29l-4.335-5.899v4.662c0 1.595-.913 2.395-2.74 2.395h-2.742V1.134h8.942c1.531 0 2.807.588 3.826 1.759 1.016 1.17 1.522 2.43 1.522 3.77 0 2.397-.932 4.12-2.798 5.168ZM45 4.883v4.249h1.58c1.55 0 2.323-.711 2.323-2.127 0-1.411-.774-2.122-2.323-2.122H45ZM85.603 6.937l3.85-4.603c.623-.756 1.474-1.133 2.547-1.133h2.74v16.213c0 1.595-.915 2.393-2.74 2.393-1.828 0-2.74-.798-2.74-2.393V9.802l-3.657 4.276L81.95 9.83v7.585c0 1.595-.918 2.393-2.744 2.393h-2.745V1.2h2.745c1.047.01 1.894.385 2.521 1.133l3.876 4.603Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m85.603 6.937 3.85-4.603c.623-.756 1.474-1.133 2.547-1.133h2.74v16.213c0 1.595-.915 2.393-2.74 2.393-1.828 0-2.74-.798-2.74-2.393V9.802l-3.657 4.276L81.95 9.83v7.585c0 1.595-.918 2.393-2.744 2.393h-2.745V1.2h2.745c1.047.01 1.894.385 2.521 1.133l3.876 4.603ZM116 10.491c0-2.867-.931-5.186-2.801-6.96-1.864-1.77-4.227-2.656-7.093-2.658-2.855 0-5.213.887-7.062 2.658-1.847 1.774-2.77 4.093-2.77 6.96 0 3.26 1.117 5.936 3.822 7.764.272.187.349.253.44.29.518.285.532.074.856-.586.565-1.149 1.512-3.62.584-4.803-2.053-3.106-.029-7.442 4.165-7.57 2.724-.086 4.937 2.202 4.937 4.917 0 2.63-2.092 4.774-4.681 4.913-.31.016-.522.007-.808.168-.332.188-.68.491-.85.67-.657.689-1.29 1.6-1.604 2.533-.266.782-.406.889.434 1.075.856.188 1.628.272 2.624.272 2.899 0 5.224-.89 7.059-2.672 1.831-1.783 2.748-4.104 2.748-6.97Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116 10.491c0-2.867-.931-5.186-2.801-6.96-1.864-1.77-4.227-2.656-7.093-2.658-2.855 0-5.213.887-7.062 2.658-1.847 1.774-2.77 4.093-2.77 6.96 0 3.26 1.117 5.936 3.822 7.764.272.187.349.253.44.29.518.285.532.074.856-.586.565-1.149 1.512-3.62.584-4.803-2.053-3.106-.029-7.442 4.165-7.57 2.724-.086 4.937 2.202 4.937 4.917 0 2.63-2.092 4.774-4.681 4.913-.31.016-.522.007-.808.168-.332.188-.68.491-.85.67-.657.689-1.29 1.6-1.604 2.533-.266.782-.406.889.434 1.075.856.188 1.628.272 2.624.272 2.899 0 5.224-.89 7.059-2.672 1.831-1.783 2.748-4.104 2.748-6.97ZM55.053 10.517c0 2.865.933 5.185 2.802 6.96 1.865 1.77 4.233 2.657 7.097 2.657 2.856 0 5.213-.887 7.064-2.658 1.845-1.772 2.767-4.094 2.767-6.959 0-3.262-1.115-5.935-3.821-7.765-.27-.187-.347-.253-.44-.29-.516-.284-.535-.075-.859.586-.56 1.148-1.512 3.621-.58 4.805 2.049 3.104.029 7.44-4.164 7.57-2.726.084-4.942-2.204-4.942-4.92 0-2.63 2.096-4.773 4.686-4.91.309-.018.517-.01.806-.17.334-.186.677-.491.85-.67.653-.687 1.29-1.6 1.606-2.533.264-.78.404-.889-.441-1.073a11.404 11.404 0 0 0-2.62-.274c-2.897 0-5.221.892-7.058 2.672-1.83 1.782-2.753 4.104-2.753 6.972Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.053 10.517c0 2.865.933 5.185 2.802 6.96 1.865 1.77 4.233 2.657 7.097 2.657 2.856 0 5.213-.887 7.064-2.658 1.845-1.772 2.767-4.094 2.767-6.959 0-3.262-1.115-5.935-3.821-7.765-.27-.187-.347-.253-.44-.29-.516-.284-.535-.075-.859.586-.56 1.148-1.512 3.621-.58 4.805 2.049 3.104.029 7.44-4.164 7.57-2.726.084-4.942-2.204-4.942-4.92 0-2.63 2.096-4.773 4.686-4.91.309-.018.517-.01.806-.17.334-.186.677-.491.85-.67.653-.687 1.29-1.6 1.606-2.533.264-.78.404-.889-.441-1.073a11.404 11.404 0 0 0-2.62-.274c-2.897 0-5.221.892-7.058 2.672-1.83 1.782-2.753 4.104-2.753 6.972Z"
      fill="#ED1C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.828 8.908h4.277a2.14 2.14 0 0 1 2.14 2.128v.001a1.07 1.07 0 0 1-1.07 1.063h-2.14c-1.764 0-3.207-1.436-3.207-3.192Z"
      fill="#090909"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.828 8.908h4.277a2.14 2.14 0 0 1 2.14 2.128v.001a1.07 1.07 0 0 1-1.07 1.063h-2.14c-1.764 0-3.207-1.436-3.207-3.192Z"
      fill="#03428E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.223 4.526c1.993.887 3.325 2.29 3.708 4.425.04.216.06.28.056.347.021.235-.02.312-.163.34-.038.006-.084.01-.138.013-2.967.525-5.535 2.456-8.166 1.008-.506-.28-.51-.734-.08-.77 3.334.038 4.256.184 4.52-.71.183-.605.162-.93-.603-1.434C6.81 6.727 4.528 7.144 3.543 9.17c-.793 1.626.003 3.592 1.663 4.331a3.316 3.316 0 0 0 4.276-1.504c.095-.183.146-.31.323-.442.204-.152.483-.278.64-.333.597-.21 1.329-.347 1.984-.284.551.052.654-.004.537.557a7.438 7.438 0 0 1-.544 1.661c-.788 1.757-1.964 2.92-3.552 3.55-1.586.624-3.256.553-5.01-.226C2.107 15.7.943 14.505.367 12.89-.209 11.28-.107 9.604.672 7.87c.775-1.73 1.959-2.918 3.545-3.557 1.584-.636 3.254-.565 5.006.214Z"
      fill="#090909"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.223 4.526c1.993.887 3.325 2.29 3.708 4.425.04.216.06.28.056.347.021.235-.02.312-.163.34-.038.006-.084.01-.138.013-2.967.525-5.535 2.456-8.166 1.008-.506-.28-.51-.734-.08-.77 3.334.038 4.256.184 4.52-.71.183-.605.162-.93-.603-1.434C6.81 6.727 4.528 7.144 3.543 9.17c-.793 1.626.003 3.592 1.663 4.331a3.316 3.316 0 0 0 4.276-1.504c.095-.183.146-.31.323-.442.204-.152.483-.278.64-.333.597-.21 1.329-.347 1.984-.284.551.052.654-.004.537.557a7.438 7.438 0 0 1-.544 1.661c-.788 1.757-1.964 2.92-3.552 3.55-1.586.624-3.256.553-5.01-.226C2.107 15.7.943 14.505.367 12.89-.209 11.28-.107 9.604.672 7.87c.775-1.73 1.959-2.918 3.545-3.557 1.584-.636 3.254-.565 5.006.214Z"
      fill="#03428E"
    />
  </svg>
);
