import Link from 'next/link';
import { ReactNode, AnchorHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  APPEARANCE_MAPS,
  ButtonAppearance,
  BUTTON_BASE,
} from '../Button/Button';

interface ButtonLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  appearance?: ButtonAppearance;
  fluid?: boolean;
  href: string;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
}

export const ButtonLink = ({
  href,
  children,
  appearance = ButtonAppearance.PRIMARY,
  fluid = false,
  className,
  iconAfter,
  iconBefore,
}: ButtonLinkProps) => {
  return (
    <Link
      href={href}
      className={twMerge(
        BUTTON_BASE,
        APPEARANCE_MAPS[appearance],
        fluid ? 'w-full' : 'w-fit',
        className,
        'gap-2'
      )}
    >
      {iconBefore}
      {children}
      {iconAfter}
    </Link>
  );
};
