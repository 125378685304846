import { useTranslation } from 'next-i18next';
import { clsx } from 'clsx';

import { Button } from '@components/atoms/Button';

export type LoadMoreProps = {
  isLoading: boolean;
  onLoadMore: (page?: number) => void;
};

export const LoadMore = ({ isLoading, onLoadMore }: LoadMoreProps) => {
  const { t } = useTranslation('common');

  return (
    <div
      className={clsx(
        'mx-auto flex max-w-xs flex-col items-center justify-center',
        'gap-y-4 py-8 text-center'
      )}
    >
      <Button
        fluid
        type="button"
        loading={isLoading}
        disabled={isLoading}
        onClick={() => {
          onLoadMore();
        }}
      >
        {t('loadMore')}
      </Button>
    </div>
  );
};
