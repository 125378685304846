import { useRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import ContentLoader from 'react-content-loader';
import debounce from 'lodash.debounce';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { useMediaQuery } from 'epromo-lib/hooks/useMediaQuery';
// eslint-disable-next-line max-len
import { useQuickSearchProducts } from 'epromo-lib/hooks/useQuickSearchProducts';
import { useRedirect } from 'epromo-lib/hooks/useRedirect';
import { useDetectMobileUserAgent } from 'epromo-lib/hooks/useDetectMobile';
import { ProductContract } from 'epromo-types';
import { CategoryContract } from 'epromo-types/Inventory';
import { addLeadingSlash, resolveUrl } from 'epromo-lib/utils/slug';
import { useAppState, useAppStateTrackedStore } from 'epromo-lib/store/app';

import {
  userProfileSelector,
  useUserProfile,
} from 'epromo-lib/store/auth/user-profile';
import { useUserSearch } from 'epromo-lib/store/search';
import { StaticLinks } from 'epromo-lib/constants';
import EmptySearch from 'ui/components/atoms/assets/images/empty_search.svg';
import useOnClickOutside from 'epromo-lib/hooks/useOnClickOutside';
import { screens } from 'epromo-lib/utils/tailwindTheme';
// eslint-disable-next-line max-len
import { useNativeAppNotification } from 'epromo-lib/hooks/useNativeAppNotification';

import { ProductImage } from '@components/molecules/ProductImage';

import { Button } from '@components/atoms/Button';
import { ButtonLink } from '@components/atoms/ButtonLink';
import { HelperText } from '@components/atoms/HelperText';
import { TextInput } from '@components/atoms/TextInput';
// prettier-ignore
import { GLOBAL_NOTIFICATIONS_ID }
  from '@components/organisms/GlobalNotificationsBar';
import { Icon } from '@components/atoms/Icon/Icon';

interface ProductLineProps extends ProductContract {
  isLoggedIn: boolean;
}

interface CategoryLineProps extends CategoryContract {
  isLoggedIn: boolean;
}

export function SearchCategoryLine({ name, isLoggedIn }: CategoryLineProps) {
  return (
    <div
      className={twMerge(
        'inline-flex',
        'py-2 pr-2 group-hover:rounded-lg',
        'rounded-lg bg-white md:bg-none',
        'mb-2 w-full items-center justify-between',
        'text-neutral-600',
        'hover:bg-neutral-100',
        'hover:text-default-primary'
      )}
    >
      <p className={twMerge('pl-2 text-sm font-medium')}>{name}</p>
      <Icon name="chevronRight" color="black" />
    </div>
  );
}

export function SearchProductLine({
  isLoggedIn,
  name,
  inStock,
  price,
  ...rest
}: ProductLineProps) {
  const { t } = useTranslation('common');
  return (
    <div
      className={twMerge(
        'inline-flex',
        'mb-[3px] rounded-2xl p-[6px] pr-4',
        'w-full',

        isLoggedIn
          ? !inStock
            ? 'bg-negative-50'
            : 'bg-neutral-50'
          : 'bg-neutral-50',
        'group w-full items-center',
        'hover:bg-neutral-100'
      )}
    >
      <div className={twMerge('h-14 w-14', 'shrink-0 rounded-2xl bg-white')}>
        <ProductImage
          {...{ ...rest, name, inStock }}
          checkInStock
          variant="searchThumb"
          className="rounded-2xl"
        />
      </div>
      <p
        className={twMerge(
          'inline-flex flex-grow text-xs font-medium text-neutral-600',
          'mr-4 overflow-x-hidden whitespace-normal pl-4 md:mr-12'
        )}
      >
        {name}
      </p>
      <div className="-mr-2 flex shrink-0">
        {!isLoggedIn ? (
          <ButtonLink fluid href={StaticLinks.Login} className="mb-1">
            {t('loginToBuy')}
          </ButtonLink>
        ) : isLoggedIn && !inStock ? (
          <HelperText
            className="pr-4 text-sm font-bold"
            text={`${t('outOfStock')}!`}
            variant="error"
          />
        ) : null}
      </div>
    </div>
  );
}

type SearchNoResultsProps = {
  searchInput?: string;
  onCta: () => void;
  title?: string;
  hideDescription?: boolean;
};

export function SearchNoResults({
  searchInput,
  onCta,
  title,
  hideDescription = false,
}: SearchNoResultsProps) {
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(`(max-width: ${screens?.sm})`);

  return (
    <div className={twMerge('mx-auto px-4 pb-1', 'pt-7 text-center md:pt-3')}>
      <EmptySearch className="mx-auto" />
      <p className={twMerge('py-4 font-semibold', 'text-secondary-500')}>
        {title ??
          t('noResultsBySearchTerm', {
            searchTerm: searchInput,
          })}
      </p>
      {hideDescription !== true && (
        <p className={twMerge('font-normal text-neutral-500')}>
          {t('changeSearchTerm')}
        </p>
      )}
      <div className="py-4">
        <Button
          fluid={isMobile}
          onClick={(e) => {
            e.stopPropagation();
            onCta();
          }}
        >
          {t('searchInCategories')}
        </Button>
      </div>
    </div>
  );
}

type RecentSearchResultsProps = {
  isLoading: boolean;
  isLoggedIn: boolean;
  searchInput: string | undefined;
  products: ProductContract[];
};
export function RecentSearchResults({
  isLoggedIn,
  isLoading,
  products,
  searchInput,
}: RecentSearchResultsProps) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const canShowResults =
    !isLoading && !searchInput && isLoggedIn && products.length > 0;
  if (!canShowResults) {
    return null;
  }
  return (
    <div
      className={twMerge(
        'flex w-full flex-col',
        'overflow-hidden rounded-[20px]',
        'border border-neutral-200'
      )}
    >
      <SimpleBar style={{ maxHeight: 400 }}>
        <div className="px-4">
          <p
            className={twMerge(
              'text-secondary-500',
              'py-2 text-center text-sm font-medium'
            )}
          >
            {t('youRecentlySearched')}
          </p>
          {products.map((product) => (
            <Link
              key={product.id}
              className={twMerge(
                'flex flex-shrink-0',
                'items-center justify-start gap-x-1',
                'group'
              )}
              href={addLeadingSlash(resolveUrl(router.locale, product)) || ''}
            >
              <SearchProductLine {...product} isLoggedIn={isLoggedIn} />
            </Link>
          ))}
        </div>
      </SimpleBar>
    </div>
  );
}
type SearchLoaderProps = {
  isLoading: boolean;
};
export function SearchLoader({ isLoading }: SearchLoaderProps) {
  if (!isLoading) {
    return null;
  }
  return (
    <ContentLoader
      speed={1.5}
      width="100%"
      height={160}
      viewBox="0 0 636 160"
      backgroundColor="#F2F3F7"
      foregroundColor="#fff"
    >
      <rect
        x="0.204102"
        y="6"
        width="100%"
        height="28"
        rx="14"
        fill="#D7DAE5"
      />
      <rect
        x="0.204102"
        y="50"
        width="100%"
        height="28"
        rx="14"
        fill="#D7DAE5"
      />
      <rect
        x="0.204102"
        y="94"
        width="100%"
        height="68"
        rx="16"
        fill="#D7DAE5"
      />
    </ContentLoader>
  );
}

type SearchResultsProps = {
  isLoading: boolean;
  isFetched: boolean;
  searchInput: string | undefined;
  data: (ProductContract | CategoryContract)[];
  isLoggedIn: boolean;
  showAll: boolean;
  onCta: () => void;
};

export function SearchResults({
  isLoading,
  isFetched,
  data,
  searchInput,
  isLoggedIn,
  showAll,
  onCta,
}: SearchResultsProps) {
  const { t } = useTranslation('common');
  const router = useRouter();

  if (isLoading) {
    return null;
  }
  if (!isFetched) {
    return null;
  }
  const encode = encodeURIComponent;

  return (
    <div
      className={twMerge(
        'flex',
        'flex-col rounded-[20px] pb-2 pt-4',
        'border border-neutral-200'
      )}
    >
      {data.length === 0 && (
        <div className="mx-auto max-w-xs">
          <SearchNoResults searchInput={searchInput} onCta={onCta} />
        </div>
      )}

      {data.length > 0 && (
        <div className="flex flex-col">
          <SimpleBar style={{ maxHeight: 400 }}>
            <div className="px-4">
              {data.map((searchItem) => (
                <Link
                  key={searchItem.id}
                  className={twMerge(
                    'flex flex-shrink-0',
                    'items-center justify-start gap-x-1',
                    'group'
                  )}
                  href={
                    addLeadingSlash(resolveUrl(router.locale, searchItem)) || ''
                  }
                  legacyBehavior
                >
                  <a>
                    {/* @ts-ignore */}
                    {typeof searchItem.isNewProduct !== 'undefined' ? (
                      <SearchProductLine
                        {...(searchItem as ProductContract)}
                        isLoggedIn={isLoggedIn}
                      />
                    ) : (
                      <SearchCategoryLine
                        {...(searchItem as CategoryContract)}
                        isLoggedIn={isLoggedIn}
                      />
                    )}
                  </a>
                </Link>
              ))}
            </div>
          </SimpleBar>
          {showAll && (
            <div
              className={twMerge(
                'border-t',
                'border-neutral-200',
                'w-full pt-2 text-center text-sm font-medium'
              )}
            >
              <Link
                href={`/search?term=${encode(`${searchInput}`)}`}
                legacyBehavior
              >
                <a
                  className={twMerge(
                    'inline-flex gap-x-2',
                    'items-center justify-self-center',
                    isLoggedIn
                      ? 'hover:text-skin-text-hover'
                      : 'hover:text-black'
                  )}
                >
                  {t('viewAll')}
                  <Icon name="chevronRight" />
                </a>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function SearchProducts() {
  const { t } = useTranslation('common');
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;
  const { products } = useUserSearch();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [dropdownLeft, setDropdownLeft] = useState(0);
  const { toSearchPage } = useRedirect();
  const isTabletView = useMediaQuery(`(max-width: ${screens?.md})`);
  const {
    setSearchInputHasFocus,
    searchInputHasFocus,
    searchInput,
    setSearchInput,
    searchIsOpen,
    openSearch,
    closeSearch,
  } = useAppState();
  const { setDebouncedSearch, isLoading, isFetched, data, showAll } =
    useQuickSearchProducts(searchInput);

  const { isMobileUserAgent } = useDetectMobileUserAgent();
  const { nativeAppNotificationIsClosed } = useNativeAppNotification();

  useOnClickOutside(containerRef, () => {
    if (isTabletView) {
      return;
    }
    if (searchIsOpen) {
      closeSearch();
    }
  });

  const debouncedChangeHandler = useCallback(
    debounce(setDebouncedSearch, 800),
    []
  );

  useEffect(() => {
    debouncedChangeHandler(searchInput);
  }, [searchInput]);

  const debouncedSetDropdownLeft = useCallback(
    debounce(() => {
      setDropdownLeft(inputRef?.current?.getBoundingClientRect().left || 0);
    }, 150),
    [inputRef]
  );

  useEffect(() => {
    debouncedSetDropdownLeft();
    window.addEventListener('resize', debouncedSetDropdownLeft);
    return () => {
      window.removeEventListener('resize', debouncedSetDropdownLeft);
    };
  }, []);

  const moreMarginFromTop = isMobileUserAgent && !nativeAppNotificationIsClosed;

  return (
    <div
      ref={containerRef}
      className={twMerge(
        'relative pl-14',
        'hidden md:flex',
        'flex-grow lg:flex-grow-0'
      )}
    >
      <div
        className={twMerge(
          searchInputHasFocus ? 'w-[520px] lg:w-[552px]' : 'w-96',
          'relative',
          'z-40',
          'transition-all duration-150 ease-in-out'
        )}
      >
        <div
          className={twMerge(
            'pointer-events-none absolute inset-y-0',
            'text-secondary-500 left-0 z-40 flex items-center pl-4'
          )}
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </div>
        {searchInput && (
          <button
            type="button"
            onClick={(evt) => {
              closeSearch();
            }}
            className={twMerge(
              'absolute inset-y-0',
              'text-secondary-500 right-0 z-40 pr-4'
            )}
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
        <TextInput
          ref={inputRef}
          onFocus={(evt) => {
            evt.stopPropagation();
            setSearchInputHasFocus(true);
            openSearch();
          }}
          type="search"
          autoComplete="off"
          placeholder={t('search')}
          name="search"
          value={searchInput}
          className={twMerge(
            '-z-[40] w-full rounded-3xl',
            'px-12 transition-all duration-150 ease-in-out'
          )}
          autoFocus={false}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              if (searchInput) {
                toSearchPage(searchInput);
              }
            }
          }}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        {searchIsOpen && (
          <div
            onClick={(evt) => {
              closeSearch();
            }}
            className={twMerge(
              'inset-0 z-20 hidden bg-black/10 sm:fixed sm:flex',
              'h-screen',
              moreMarginFromTop
                ? 'top-[195px]'
                : // eslint-disable-next-line max-len
                  window &&
                    window.document.getElementById(GLOBAL_NOTIFICATIONS_ID)
                  ? 'top-[158px]'
                  : 'top-[110px]'
            )}
          >
            <div
              className={twMerge(
                'absolute z-10 w-[520px] lg:w-[552px]',
                'top-0 rounded-[20px] bg-white'
              )}
              style={{
                left: dropdownLeft,
              }}
            >
              {isLoading ? (
                <div className="rounded-xl border border-neutral-200 px-4 py-4">
                  <SearchLoader isLoading={isLoading} />
                </div>
              ) : (
                <>
                  <RecentSearchResults
                    isLoading={isLoading}
                    isLoggedIn={isLoggedIn}
                    searchInput={searchInput}
                    products={products}
                  />

                  {searchInput && (
                    <SearchResults
                      isLoading={isLoading}
                      isLoggedIn={isLoggedIn}
                      searchInput={searchInput}
                      showAll={showAll}
                      isFetched={isFetched}
                      data={data}
                      onCta={() => {
                        closeSearch();
                        document
                          .getElementById('btn-categories-burger')
                          ?.click();
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
