import { HTMLAttributes } from 'react';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { currency } from 'epromo-lib/constants';
import { formatPrice } from 'epromo-lib/utils/priceHelpers';
import { TareContract } from 'epromo-types';

import { HelperText } from '@components/atoms/HelperText';
import { TarePrice } from '@components/atoms/TarePrice';

export interface PriceProps extends HTMLAttributes<HTMLSpanElement> {
  price: number;
}

export interface PriceDiscountedProps extends HTMLAttributes<HTMLSpanElement> {
  price: number;
  priceCoefficient: string;
  tare: TareContract;
  basePrice: number;
  isSaleOut?: boolean;
}

export function Price({ price, className }: PriceProps) {
  return (
    <span
      className={twMerge(
        clsx('text-base font-bold', 'inline-flex gap-x-1'),
        className
      )}
    >
      <span>{formatPrice(price)}</span>
      <span>{currency}</span>
    </span>
  );
}

export function DiscountedPrice({
  price,
  priceCoefficient,
  tare,
  basePrice,
  className,
  isSaleOut,
}: PriceDiscountedProps) {
  const [euros, cents] = formatPrice(price).split(',');
  return (
    <div className="flex gap-x-3">
      <div
        className={
          'bg-discount-300 flex w-min flex-col justify-center rounded-md'
        }
      >
        <div className="bg-discount-200 rounded-md p-2">
          <span className={twMerge('flex items-center gap-x-0.5', className)}>
            <span className="text-[1.75rem] font-extrabold leading-[1.75rem]">
              {euros}
            </span>
            <span className="flex flex-col text-xs font-black leading-3">
              <span>{cents}</span>
              <span>{currency}</span>
            </span>
          </span>
        </div>
        <div className="px-2 py-1 !leading-[1.125rem]">
          <div className="flex w-full justify-between">
            <HelperText text={priceCoefficient} />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-between pb-1 pt-3.5">
        {!isSaleOut && (
          <Price
            price={basePrice}
            className={clsx(
              'text-negative-500',
              'text-base font-semibold leading-6 line-through'
            )}
          />
        )}
        <span className="flex w-full justify-end">
          <TarePrice tare={tare} />
        </span>
      </div>
    </div>
  );
}
