import resolveConfig from 'tailwindcss/resolveConfig';
import defaultTheme from 'tailwindcss/defaultTheme';
import { Config, KeyValuePair, ThemeConfig } from 'tailwindcss/types/config';

const tailwindConfig = require('../../config/tailwind.config');

const config = resolveConfig(tailwindConfig || defaultTheme) as Config;

export const theme = config.theme as ThemeConfig;

export const screens = theme.screens as KeyValuePair<string, string>;
