import { Popover, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';
import Link from 'next/link';
import clsx from 'clsx';

import { getAddressId } from 'epromo-lib/hooks/useDeliveryAddress';
import { useAddresses } from 'epromo-lib/hooks/useAddresses';
import { useCredentialsLogin } from 'epromo-lib/hooks/useCredentialsLogin';
import { StaticLinks, THEMES } from 'epromo-lib/constants';

import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';

import { AvatarCircle } from '@components/atoms/AvatarCircle';
import { ButtonLink } from '@components/atoms/ButtonLink';
import { Icon } from '@components/atoms/Icon/Icon';

import { CreditBalanceBox } from '../CreditBalanceBox';

import {
  b2bUserMenuLinks,
  b2cUserMenuLinks,
  baseUserMenuLinks,
} from './constants';

export const getUserMenuLinks = (isB2cAddress: boolean) => {
  const extraLinks = isB2cAddress ? b2cUserMenuLinks : b2bUserMenuLinks;

  return [...baseUserMenuLinks, ...extraLinks];
};
interface IUserMenu {
  popoverButtonContent: React.ReactNode;
  userName: string;
  userMail: string;
}

export const UserMenu = ({
  popoverButtonContent,
  userName,
  userMail,
}: IUserMenu) => {
  const addressId = getAddressId();
  const isB2cAddress = isDeliveryAddressB2c(addressId);

  const { data: addressesData } = useAddresses(true);

  const hasB2bAddress = !!addressesData?.b2b.length;

  const { logout } = useCredentialsLogin();
  const { t } = useTranslation('common');
  let [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  let { styles } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const userMenuLinks = getUserMenuLinks(isB2cAddress);

  return (
    <Popover className="relative">
      <>
        <Popover.Button
          ref={setReferenceElement}
          className={clsx(
            'flex items-center',
            'rounded-md py-2 focus:outline-none focus-visible:ring-2',
            'focus-visible:ring-white focus-visible:ring-opacity-75'
          )}
        >
          {popoverButtonContent}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            className={clsx(
              'absolute z-10 mt-4 rounded-b-2xl rounded-t-2xl border',
              'w-72 border-neutral-200 bg-white '
            )}
          >
            <div className="flex flex-row p-4">
              <AvatarCircle name={userName} />
              <div className="flex flex-col overflow-hidden pl-3">
                <div className="text-sm font-bold text-black">{userName}</div>
                <div
                  className={clsx(
                    'text-xs text-gray-500',
                    'overflow-x-hidden text-ellipsis'
                  )}
                >
                  {userMail}
                </div>
              </div>
            </div>

            {!isB2cAddress && (
              <div className="px-4">
                <CreditBalanceBox />
              </div>
            )}

            <div
              className={clsx(
                'flex flex-col  border-neutral-200 p-4',
                isB2cAddress && 'border-t'
              )}
            >
              {userMenuLinks.map((link) => {
                return (
                  <Link
                    key={link.href}
                    href={link.href}
                    passHref
                    className={clsx(
                      'flex flex-row items-center',
                      'pb-3 last:pb-0 hover:underline'
                    )}
                  >
                    <Icon
                      name={link.iconName}
                      className={clsx(
                        link.iconName === 'cardClient' ? 'text-white' : ''
                      )}
                    />
                    <div className="pl-3 text-xs ">{t(link.transKey)}</div>
                  </Link>
                );
              })}

              {!hasB2bAddress && (
                <div data-theme={THEMES.B2B}>
                  <ButtonLink
                    href={StaticLinks.SignForBusinessAccount}
                    fluid
                    className="mt-3"
                  >
                    {t('createB2BAcc')}
                  </ButtonLink>
                </div>
              )}
            </div>
            <div
              className={clsx(
                'flex flex-row items-center border-t border-neutral-200 p-4 '
              )}
            >
              <Icon name="logout" color="red" />
              <div
                className={clsx(
                  'text-negative-500 cursor-pointer',
                  'pl-2 text-xs hover:underline '
                )}
                onClick={() => logout()}
              >
                {t('logOut')}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};
