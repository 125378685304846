import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import { clsx } from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { IDeviceInfo, NativeAppNotificationStatus } from 'epromo-types';
import { COOKIE_NATIVE_APP_NOTIFICATION_KEY } from 'epromo-lib/constants';

import { setCookie } from 'epromo-lib/utils/cookie';

import { Button, ButtonAppearance } from '@components/atoms/Button';
import EPromoAppLogo from '@components/atoms/assets/images/epromoappicon.png';

const {
  publicRuntimeConfig: { ePromoAppIosUrl, ePromoAndroidUrl },
} = getConfig();

type MobileAppSuggestionDialogProps = {
  deviceInfo?: IDeviceInfo;
  nativeAppNotificationStatus?: string;
};

export function MobileAppSuggestionDialog({
  deviceInfo,
  nativeAppNotificationStatus,
}: MobileAppSuggestionDialogProps) {
  const { t } = useTranslation('common');

  const [nativeAppNotificationIsClosed, setNativeAppNotificationIsClosed] =
    useState(
      nativeAppNotificationStatus === NativeAppNotificationStatus.Closed
    );
  const isAndroid = deviceInfo?.isAndroid;
  const isIos = deviceInfo?.isIos;
  const isMobileUserAgent = isAndroid || isIos;

  const close = () => {
    // eslint-disable-next-line max-len
    setCookie(
      COOKIE_NATIVE_APP_NOTIFICATION_KEY,
      NativeAppNotificationStatus.Closed
    );
    setNativeAppNotificationIsClosed(true);
  };

  const onCancel = () => {
    close();
  };

  const onConfirm = () => {
    const url = isIos ? ePromoAppIosUrl : isAndroid ? ePromoAndroidUrl : '';
    if (url) {
      window.location.href = url;
    }
    close();
  };

  if (!isMobileUserAgent) {
    return null;
  }

  if (nativeAppNotificationIsClosed) {
    return null;
  }

  return (
    <div className="flex border border-b-neutral-100 border-t-neutral-100">
      <div
        className={clsx(
          ' rounded-t-2xl bg-white',
          'text-secondary-500 flex flex-col gap-2 p-4 text-sm font-normal',
          'w-full'
        )}
      >
        <div className="flex flex-col">
          <div className="flex items-center gap-4">
            <button type="button" onClick={onCancel}>
              <XMarkIcon
                className="h-6 w-6 text-neutral-400"
                aria-hidden="true"
              />
            </button>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={EPromoAppLogo.src}
              alt="eROMO native app logo"
              width={51}
              height={51}
            />
            <p className="flex flex-grow font-bold">ePROMO</p>
            <Button
              appearance={ButtonAppearance.PRIMARY}
              onClick={onConfirm}
              fluid
              className="w-[140px]"
            >
              {t('downloadApp')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

MobileAppSuggestionDialog.displayName = 'MobileAppSuggestionDialog';
